import axios from 'axios';
import formats from '../utils/formats';

//const ENDPOINT = "https://api-ristoratori.mycia.it/v1/lang/:lang/courses/:id";
//export const ENDPOINT = "https://api-ristoratori.mycia.it/translation/v1/?type=dishes-ingredients&lang=:lang&course_id=:id";
export const ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang&consumetime=:consumetime"
export const FILTERS_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang&allergieprincipali=:allergies&nongraditi=:foods&diete=:diets&consumetime=:consumetime"
export const USERS_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/index_users.php?id=:id&lang=:lang&users=:token&consumetime=:consumetime"
export const TASTINGMENU_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/?id=:id&lang=:lang&tasting_menu=:tasting_menu&consumetime=:consumetime"
export const USERS_TASTINGMENU_ENDPOINT = "https://api-ristoratori.mycia.it/v2/courses/index_users.php?id=:id&tasting_menu=:tasting_menu&lang=:lang&users=:token&consumetime=:consumetime"

const credentials = {
    username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
    password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
}

async function doGet(lang, id) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;
    let response = await axios.get(
        formats.formatUrl(ENDPOINT, { "lang": lang, "id": id, "consumetime": consumetime }),
        {
            auth: credentials
        }
    );
    return response && response.data;
}

async function doGetFilters(lang, id, allergies, foods, diets) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;

    if (typeof allergies === "undefined" || allergies === null) {
        allergies = "";
    }
    if (typeof foods === "undefined" || foods === null) {
        foods = "";
    }
    if (typeof diets === "undefined" || diets === null) {
        diets = "";
    }

    let response = await axios.get(
        formats.formatUrl(FILTERS_ENDPOINT, { "lang": lang, "id": id, "allergies": allergies, "foods": foods, "diets": diets, "consumetime": consumetime }),
        {
            auth: credentials
        }
    );

    return response && response.data;
}

async function doGetUserFilters(lang, id, token) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;
    let response = await axios.get(formats.formatUrl(USERS_ENDPOINT, { "lang": lang, "id": id, "token": token, "consumetime": consumetime }),
        {
            auth: credentials
        });

    return response && response.data;
}

async function doGetTastingMenu(lang, id, tastingMenuId) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;
    let response = await axios.get(formats.formatUrl(TASTINGMENU_ENDPOINT, { "id": id, "lang": lang, "tasting_menu": tastingMenuId, "consumetime": consumetime }),
        {
            auth: credentials
        });

    return response && response.data;
}

async function doGetUserFiltersTastingMenu(lang, id, tastingMenuId, token) {
    let consumetime = new Date().getHours() <= 15 ? 0 : 1;
    let response = await axios.get(formats.formatUrl(USERS_TASTINGMENU_ENDPOINT, { "lang": lang, "id": id, "tasting_menu": tastingMenuId, "token": token, "consumetime": consumetime }),
        {
            auth: credentials
        });

    return response && response.data;
}

const coursesClient = {
    doGet,
    doGetFilters,
    doGetUserFilters,
    doGetTastingMenu,
    doGetUserFiltersTastingMenu,
};
export default coursesClient;
